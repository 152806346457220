import styled from "styled-components";
import { useSelector } from "react-redux";
import { Spin } from "antd";

import Header from "@components/Header";
import Footer from "@components/Footer";
import { layoutAuthInterface } from "./interface";
import { stateInterface } from "@redux/Interface";

const Layout = ({ children }: layoutAuthInterface) => {
  const store = useSelector((state: stateInterface) => state.store);
  return (
    <Content className="layout">
      <Header />
      <div className="layout__content">{children}</div>
      <Footer/>
    </Content>
  );
};

export default Layout;

interface styleInterface {}

const Content = styled.div<styleInterface>`
  position: relative;
  width: 100%;
  .layout {
    &__content {
      width: 100%;
    }
    &__aside-menu {
      position: fixed;
      width: 19%;
      transition: 0.3s all ease;
      z-index: 100;
      &.toggle-hide {
        width: 7.3%;
      }
      @media only screen and (max-width: 1024px) {
        display: none;
      }
    }
    &__child {
      position: relative;
      width: calc(100% - 19%);
      min-height: calc(100vh - 4.5vw);
      margin-left: auto;
      padding: 2vw 2vw;
      transition: all 0.3s ease;
      &.toggle-hide {
        width: calc(100% - 7.3%);
      }
      @media only screen and (max-width: 1024px) {
        width: 100%;
        min-height: calc(100vh - 7.8vw);
        padding: 30px 20px;
        &.toggle-hide {
          width: 100%;
        }
      }
      @media only screen and (max-width: 450px) {
        min-height: calc(100vh - 16vw);
        padding: 25px 15px;
      }
    }
  }
`;
