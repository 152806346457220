import { storeDataInterface } from "../Interface";

const initState = {
  loading: false,
  scrollTo: "",
  hero_banners: [],
  second_banners: [],
  portfolios: {
    building: [],
    condo: [],
    house: [],
  },
};

const store = (
  state = initState,
  action: { type: string; data: storeDataInterface }
) => {
  switch (action.type) {
    case "loading":
      return { ...state, loading: action.data };
    case "scrollto":
      return { ...state, scrollTo: action.data };
    case "hero_banners":
      return { ...state, hero_banners: action.data };
    case "second_banners":
      return { ...state, second_banners: action.data };
    case "portfolios":
      return { ...state, portfolios: action.data };
    default:
      return state;
  }
};

export default store;
