import logo from "../static/images/img/logo.png";
import icon_instagram from "../static/images/img/icon_instagram.png";
import icon_facebook from "../static/images/img/icon_facebook.png";
import icon_twitter from "../static/images/img/icon_twitter.png";
import icon_youtube from "../static/images/img/icon_youtube.png";
import ex_banner from "../static/images/img/ex_banner.png";
import hamburger from "../static/images/img/hamburger.png";
import ex_banner_mobile from "../static/images/img/ex_banner_mobile.png";
import package_img from "../static/images/img/package_img.png";
import package_step from "../static/images/img/package_step.png";
import package_step_mobile from "../static/images/img/package_step_mobile.png";
import ex_ads from "../static/images/img/ex_ads.png";
import ex_ads_mobile from "../static/images/img/ex_ads_mobile.png";
import ex_work_home_1 from "../static/images/img/ex_work_home_1.png";
import ex_work_home_2 from "../static/images/img/ex_work_home_2.png";
import ex_work_home_3 from "../static/images/img/ex_work_home_3.png";
import ex_work_home_4 from "../static/images/img/ex_work_home_4.png";
import ex_work_home_5 from "../static/images/img/ex_work_home_5.png";
import ex_work_home_6 from "../static/images/img/ex_work_home_6.png";
import test from "../static/images/img/test.png";
import vision_img from "../static/images/img/vision_img.png";
import dot_pink from "../static/images/img/dot_pink.png";
import dot_blue from "../static/images/img/dot_blue.png";
import dot_green from "../static/images/img/dot_green.png";
import service_1 from "../static/images/img/service_1.png";
import service_2 from "../static/images/img/service_2.png";
import service_3 from "../static/images/img/service_3.png";
import service_4 from "../static/images/img/service_4.png";
import service_line from "../static/images/img/service_line.png";
import partners_1 from "../static/images/img/partners_1.png";
import partners_2 from "../static/images/img/partners_2.png";
import partners_3 from "../static/images/img/partners_3.png";
import partners_4 from "../static/images/img/partners_4.png";
import partners_5 from "../static/images/img/partners_5.png";
import partners_6 from "../static/images/img/partners_6.png";
import partners_7 from "../static/images/img/partners_7.png";
import partners_8 from "../static/images/img/partners_8.png";
import facebook from "../static/images/img/facebook.png";
import fastwork from "../static/images/img/fastwork.png";
import ex_avatar_facebook from "../static/images/img/ex_avatar_facebook.png";
import ex_avatar_fastwork from "../static/images/img/ex_avatar_fastwork.png";
import emo_facebook from "../static/images/img/emo_facebook.png";
import message from "../static/images/img/message.png";
import message_mobile from "../static/images/img/message_mobile.png";
import fastwork_message from "../static/images/img/fastwork_message.png";
import fastwork_message_mobile from "../static/images/img/fastwork_message_mobile.png";
import about_img from "../static/images/img/about_img.png";
import about_1 from "../static/images/img/about_1.png";
import about_2 from "../static/images/img/about_2.png";
import about_3 from "../static/images/img/about_3.png";
import contact from "../static/images/img/contact.png";
import icon_email_blue from "../static/images/img/icon_email_blue.png";
import icon_phone_blue from "../static/images/img/icon_phone_blue.png";
import icon_facebook_blue from "../static/images/img/icon_facebook_blue.png";
import icon_line_blue from "../static/images/img/icon_line_blue.png";
import icon_instagram_white from "../static/images/img/icon_instagram_white.png";
import icon_facebook_white from "../static/images/img/icon_facebook_white.png";
import icon_twitter_white from "../static/images/img/icon_twitter_white.png";
import icon_youtube_white from "../static/images/img/icon_youtube_white.png";
import qrcode from "../static/images/img/qrcode.png";
import arrow from "../static/images/img/arrow.png";
import contact_phone_1 from "../static/images/img/contact_phone_1.png";
import contact_phone_2 from "../static/images/img/contact_phone_2.png";
import logo_white from "../static/images/img/logo_white.png";
import ex_work_1 from "../static/images/img/ex_work_1.png";
import ex_work_2 from "../static/images/img/ex_work_2.png";
import ex_work_3 from "../static/images/img/ex_work_3.png";
import ex_work_4 from "../static/images/img/ex_work_4.png";
import ex_work_5 from "../static/images/img/ex_work_5.png";
import ex_work_6 from "../static/images/img/ex_work_6.png";
import work_location from "../static/images/img/work_location.png";
import work_space from "../static/images/img/work_space.png";
import nodata from "../static/images/img/nodata.png";
import nopage from "../static/images/img/nopage.png";
import ex_service_1 from "../static/images/img/ex_service_1.png";
import ex_service_2 from "../static/images/img/ex_service_2.png";
import ex_service_3 from "../static/images/img/ex_service_3.png";
import ex_service_4 from "../static/images/img/ex_service_4.png";
import number_1 from "../static/images/img/1.png";
import number_2 from "../static/images/img/2.png";
import number_3 from "../static/images/img/3.png";
import number_4 from "../static/images/img/4.png";
import icon_line from "../static/images/img/icon_line.png";
import icon_tiktok from "../static/images/img/icon_tiktok.png";
import icon_tiktok_white from "../static/images/img/icon_tiktok_white.png";
import icon_line_white from "../static/images/img/icon_line_white.png";
import ex_avatar_fastwork_2 from "../static/images/img/ex_avatar_fastwork_2.png";
import ex_avatar_fastwork_3 from "../static/images/img/ex_avatar_fastwork_3.png";
import ex_avatar_fastwork_4 from "../static/images/img/ex_avatar_fastwork_4.png";
import ex_avatar_fastwork_5 from "../static/images/img/ex_avatar_fastwork_5.png";
import ex_avatar_fastwork_7 from "../static/images/img/ex_avatar_fastwork_7.png";
import ex_avatar_fastwork_1 from "../static/images/img/ex_avatar_fastwork_1.png";
import ex_avatar_fastwork_9 from "../static/images/img/ex_avatar_fastwork_9.png";
import ex_avatar_fastwork_8 from "../static/images/img/ex_avatar_fastwork_8.png";
import ex_avatar_fastwork_6 from "../static/images/img/ex_avatar_fastwork_6.png";
import ex_avatar_facebook_2 from "../static/images/img/ex_avatar_facebook_2.png";
import ex_avatar_facebook_3 from "../static/images/img/ex_avatar_facebook_3.png";
import ex_avatar_facebook_4 from "../static/images/img/ex_avatar_facebook_4.png";
import ex_avatar_facebook_5 from "../static/images/img/ex_avatar_facebook_5.png";
import ex_avatar_facebook_6 from "../static/images/img/ex_avatar_facebook_6.png";
import ex_avatar_facebook_1 from "../static/images/img/ex_avatar_facebook_1.png";
import partners_1_ from "../static/images/img/partners_1_.png";
import partners_2_ from "../static/images/img/partners_2_.png";
import partners_3_ from "../static/images/img/partners_3_.png";

import { imgInterface } from "./imgInterface";

export const imgList: imgInterface = {
  logo,
  icon_instagram,
  icon_facebook,
  icon_twitter,
  icon_youtube,
  ex_banner,
  hamburger,
  ex_banner_mobile,
  package_img,
  package_step,
  package_step_mobile,
  ex_ads,
  ex_ads_mobile,
  ex_work_home_1,
  ex_work_home_2,
  ex_work_home_3,
  ex_work_home_4,
  ex_work_home_5,
  ex_work_home_6,
  test,
  vision_img,
  dot_pink,
  dot_blue,
  dot_green,
  service_1,
  service_2,
  service_3,
  service_4,
  service_line,
  partners_1,
  partners_2,
  partners_3,
  partners_4,
  partners_5,
  partners_6,
  partners_7,
  partners_8,
  facebook,
  fastwork,
  ex_avatar_facebook,
  ex_avatar_fastwork,
  emo_facebook,
  message,
  message_mobile,
  fastwork_message,
  fastwork_message_mobile,
  about_img,
  about_2,
  about_3,
  about_1,
  contact,
  icon_email_blue,
  icon_phone_blue,
  icon_facebook_blue,
  icon_line_blue,
  icon_instagram_white,
  icon_facebook_white,
  icon_twitter_white,
  icon_youtube_white,
  qrcode,
  arrow,
  contact_phone_1,
  contact_phone_2,
  logo_white,
  ex_work_1,
  ex_work_2,
  ex_work_3,
  ex_work_4,
  ex_work_5,
  ex_work_6,
  work_location,
  work_space,
  nodata,
  nopage,
  ex_service_1,
  ex_service_2,
  ex_service_3,
  ex_service_4,
  number_1,
  number_2,
  number_3,
  number_4,
  icon_line,
  icon_tiktok,
  icon_tiktok_white,
  icon_line_white,
  ex_avatar_fastwork_2,
  ex_avatar_fastwork_3,
  ex_avatar_fastwork_4,
  ex_avatar_fastwork_5,
  ex_avatar_fastwork_7,
  ex_avatar_fastwork_1,
  ex_avatar_fastwork_9,
  ex_avatar_fastwork_8,
  ex_avatar_fastwork_6,
  ex_avatar_facebook_2,
  ex_avatar_facebook_3,
  ex_avatar_facebook_4,
  ex_avatar_facebook_5,
  ex_avatar_facebook_6,
  ex_avatar_facebook_1,
  partners_1_,
  partners_2_,
  partners_3_,
};
