import { Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  RouteProps,
} from "react-router-dom";
import ProgressBar from "react-topbar-progress-indicator";

import { Layout } from "@components/Layout";

const Home = lazy(() => import("./pages/Home"));
const Works = lazy(() => import("./pages/Works"));
const WorksDetail = lazy(() => import("./pages/WorksDetail"));
const Services = lazy(() => import("./pages/Services"));
const Error404 = lazy(() => import("./pages/Error/Error404"));

ProgressBar.config({
  barColors: {
    "0": "#4774c2",
    "1.0": "#4774c2",
  },
});

const Routes = () => {
  const PrivateRouteNotAuth = ({ children, ...rest }: RouteProps) => {
    return (
      <Route
        {...rest}
        render={() => {
          return children;
        }}
      />
    );
  };

  const PublicRoute = ({ children, ...rest }: RouteProps) => {
    return <Route {...rest} render={() => children} />;
  };

  return (
    <Router>
      <Suspense fallback={<ProgressBar />}>
        <Switch>
          <Route exact path={["/", "/works", "/works/:id", "/services"]}>
            <Layout>
              <Switch>
                <PrivateRouteNotAuth exact path="/">
                  <Home />
                </PrivateRouteNotAuth>
                <PrivateRouteNotAuth exact path="/works">
                  <Works />
                </PrivateRouteNotAuth>
                <PrivateRouteNotAuth exact path="/works/:id">
                  <WorksDetail />
                </PrivateRouteNotAuth>
                <PrivateRouteNotAuth exact path="/services">
                  <Services />
                </PrivateRouteNotAuth>
              </Switch>
            </Layout>
          </Route>
          <PublicRoute>
            <Error404 />
          </PublicRoute>
        </Switch>
      </Suspense>
    </Router>
  );
};

export default Routes;
