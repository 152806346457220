import styled from "styled-components";

import { imgList } from "@constants/img";

const Contact = () => {
  return (
    <Content className="contact">
      <div className="contact__left">
        <div className="contact__headline">ติดต่อเรา</div>
        <div className="contact__title">Contact us</div>
        <div className="contact__row">
          <a
            href="mailto: thechecker.team@gmail.com"
            className="contact__row-inner"
          >
            <img src={imgList.icon_email_blue} alt="" />
            <div>thechecker.team@gmail.com</div>
            <img src={imgList.arrow} alt="" />
          </a>
        </div>
        <div className="contact__row">
          <a href="tel:+66949296565" className="contact__row-inner">
            <img src={imgList.icon_phone_blue} alt="" />
            <div>094-929-6565</div>
            <img src={imgList.arrow} alt="" />
          </a>
        </div>
        <div className="contact__row">
          <a
            href="https://www.facebook.com/thechecker.team"
            target="_blank"
            rel="noreferrer"
            className="contact__row-inner"
          >
            <img src={imgList.icon_facebook_blue} alt="" />
            <div className="show-desktop">
              The Checker ตรวจบ้านและคอนโด โดยวิศวกรมืออาชีพ
            </div>
            <div className="show-mobile">The Checker ตรวจบ้านและคอนโด...</div>
            <img src={imgList.arrow} alt="" />
          </a>
        </div>
        <div className="contact__row">
          <a
            href="https://bit.ly/3hP4OHF"
            target="_blank"
            rel="noreferrer"
            className="contact__row-inner"
          >
            <img src={imgList.icon_line_blue} alt="" />
            <div>@thechecker</div>
            <img src={imgList.arrow} alt="" />
          </a>
        </div>
      </div>
      <img
        src={imgList.contact_phone_1}
        alt=""
        className="contact__phone contact__phone--1"
      />
      <img
        src={imgList.contact_phone_2}
        alt=""
        className="contact__phone contact__phone--2"
      />
    </Content>
  );
};

export default Contact;

const Content = styled.div`
  position: relative;
  width: 100%;
  background: #004998;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4% 11%;
  @media only screen and (max-width: 1024px) {
    padding: 6% 3%;
  }
  @media only screen and (max-width: 450px) {
    padding: 8% 5% 80%;
    flex-wrap: wrap;
    overflow: hidden;
  }
  .contact {
    &__headline {
      width: 100%;
      font-family: "Kanit";
      font-weight: 500;
      font-size: 2.8vw;
      color: #fff;
      line-height: 1;
      @media only screen and (max-width: 1024px) {
        font-size: 3.8vw;
      }
      @media only screen and (max-width: 450px) {
        font-size: 6vw;
      }
    }
    &__title {
      width: 100%;
      font-family: "Kanit";
      font-weight: 500;
      font-size: 1vw;
      color: #fff;
      margin: 3% 0 4%;
      @media only screen and (max-width: 1024px) {
        font-size: 1.4vw;
        margin: 1% 0 2.5%;
      }
      @media only screen and (max-width: 450px) {
        font-size: 3.4vw;
        margin: 0 0 5%;
      }
    }
    &__left {
      width: 48%;
      > img {
        width: 100%;
      }
      @media only screen and (max-width: 450px) {
        width: 100%;
        margin: 0 0 7%;
      }
    }
    &__phone {
      position: absolute;
      z-index: 2;
      &--1 {
        top: 0;
        left: 51%;
        width: 20.5%;
      }
      &--2 {
        bottom: 0;
        right: 8.5%;
        width: 20.5%;
      }
      @media only screen and (max-width: 1024px) {
        &--1 {
          top: 0;
          left: 51%;
          width: 25%;
        }
        &--2 {
          bottom: 0;
          right: 0%;
          width: 25%;
        }
      }
      @media only screen and (max-width: 450px) {
        &--1 {
          bottom: 6%;
          top: unset;
          left: 2%;
          width: 52%;
        }
        &--2 {
          bottom: 0;
          right: -1.5%;
          width: 52%;
        }
      }
    }
    &__row {
      width: 100%;
      margin: 0 0 4%;
    }
    &__row-inner {
      display: inline-flex;
      align-items: center;
      background: #ffffff;
      border-radius: 50px;
      font-family: "Kanit";
      font-weight: 300;
      font-size: 1vw;
      padding: 0.5vw 1vw;
      color: #004998;
      > img {
        width: 1.7vw;
      }
      > div {
        margin: 0 0.5vw;
      }
      @media only screen and (max-width: 1024px) {
        font-size: 1.4vw;
        > img {
          width: 2vw;
        }
        > div {
          margin: 0 0.8vw;
        }
      }
      @media only screen and (max-width: 450px) {
        font-size: 3.4vw;
        padding: 1.5vw 3vw;
        > img {
          width: 6vw;
        }
        > div {
          margin: 0 2vw;
        }
      }
    }
  }
`;
