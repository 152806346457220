import styled from "styled-components";
import { Element } from "react-scroll";
import { Link, useLocation } from "react-router-dom";
import {
  Link as LinkScroll,
  animateScroll as scroll,
  scroller,
} from "react-scroll";

import { imgList } from "@constants/img";
import Contact from "@components/Contact";

const Footer = () => {
  const location = useLocation();
  return (
    <Content className="footer">
      <Element name="contact">
        <Contact />
      </Element>
      <div className="footer__inner">
        <div className="footer__left">
          <Link to="/" className="footer__logo">
            <img src={imgList.logo_white} alt="" />
          </Link>
          <div className="footer__text">โทร : 094-929-6565</div>
          <div className="footer__text">อีเมล : thechecker.team@gmail.com</div>
          <div className="footer__text">
            ที่อยู่ : 822 ถ.สาธุประดิษฐ์ บางโพงพาง ยานนาวา กรุงเทพฯ 10120
          </div>
          <div className="footer__social">
            <a href="https://www.instagram.com/thechecker.inspector/" target="_blank" rel="noreferrer">
              <img src={imgList.icon_instagram_white} alt="" />
            </a>
            <a href="https://www.facebook.com/thechecker.team" target="_blank" rel="noreferrer">
              <img src={imgList.icon_facebook_white} alt="" />
            </a>
            <a href="https://bit.ly/3hP4OHF" target="_blank" rel="noreferrer">
              <img src={imgList.icon_line_white} alt="" />
            </a>
            <a href="https://www.tiktok.com/@thechecker.inspector?lang=th-TH" target="_blank" rel="noreferrer">
              <img src={imgList.icon_tiktok_white} alt="" />
            </a>
          </div>
        </div>
        <div className="footer__center">
          <div className="footer__head">เมนูทั้งหมด</div>
          {location.pathname === "/" ? (
            <LinkScroll
              activeClass=""
              className="footer__link"
              to="banner"
              spy={true}
              delay={0}
            >
              หน้าแรก
            </LinkScroll>
          ) : (
            <a href="/#home" className="footer__link">
              หน้าแรก
            </a>
          )}
          {location.pathname === "/" ? (
            <LinkScroll
              activeClass=""
              className="footer__link"
              to="review"
              spy={true}
              delay={0}
              offset={-70}
            >
              รีวิวจากลูกค้า
            </LinkScroll>
          ) : (
            <a href="/#review" className="footer__link">
              รีวิวจากลูกค้า
            </a>
          )}
          {location.pathname === "/" ? (
            <LinkScroll
              activeClass=""
              className="footer__link"
              to="about"
              spy={true}
              delay={0}
              offset={-70}
            >
              เกี่ยวกับเรา
            </LinkScroll>
          ) : (
            <a href="/#about" className="footer__link">
              เกี่ยวกับเรา
            </a>
          )}
          <a href="/services" className="footer__link">
            บริการของเรา
          </a>
          <a href="/works" className="footer__link">
            ผลงาน
          </a>
          {location.pathname === "/" ? (
            <LinkScroll
              activeClass=""
              className="footer__link"
              to="contact"
              spy={true}
              delay={0}
              offset={-70}
            >
              ติดต่อเรา
            </LinkScroll>
          ) : (
            <a href="/#contact" className="footer__link">
              ติดต่อเรา
            </a>
          )}
        </div>
        <div className="footer__right">
          <div className="footer__head">แอดไลน์เพื่อติดต่อแอดมิน</div>
          <img src={imgList.qrcode} alt="" className="footer__qr" />
        </div>
      </div>
    </Content>
  );
};

export default Footer;

const Content = styled.div`
  position: relative;
  width: 100%;
  .footer {
    &__inner {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      background: #0b0d17;
      padding: 4% 11%;
      @media only screen and (max-width: 1024px) {
        padding: 6% 3%;
      }
      @media only screen and (max-width: 450px) {
        padding: 10% 5%;
        flex-wrap: wrap;
      }
    }
    &__left {
      width: 30%;
      @media only screen and (max-width: 450px) {
        width: 100%;
      }
    }
    &__center {
      width: 20%;
      @media only screen and (max-width: 450px) {
        display: none;
      }
    }
    &__right {
      width: 30%;
      @media only screen and (max-width: 450px) {
        display: none;
      }
    }
    &__text {
      font-family: "Kanit";
      font-weight: 300;
      font-size: 1vw;
      color: #fff;
      margin: 0.5vw 0 0;
      @media only screen and (max-width: 1024px) {
        font-size: 1.4vw;
        margin: 1vw 0 0;
      }
      @media only screen and (max-width: 450px) {
        font-size: 3.4vw;
        margin: 1.5vw 0 0;
      }
    }
    &__logo {
      width: 5.5vw;
      margin: 0 0 1.5vw;
      display: block;
      > img {
        width: 100%;
      }
      @media only screen and (max-width: 1024px) {
        width: 6.5vw;
      }
      @media only screen and (max-width: 450px) {
        width: 12.5vw;
        margin: 0 0 3vw;
      }
    }
    &__social {
      display: flex;
      align-items: center;
      margin: 2vw 0 0;
      > a {
        width: 2.2vw;
        margin: 0 1vw 0 0;
        display: block;
        > img {
          width: 100%;
        }
      }
      @media only screen and (max-width: 1024px) {
        margin: 4vw 0 0;
        > a {
          width: 7vw;
          margin: 0 3vw 0 0;
        }
      }
    }
    &__head {
      font-family: "Kanit";
      font-weight: 300;
      font-size: 1.2vw;
      color: #fff;
      margin: 0 0 1vw;
      @media only screen and (max-width: 1024px) {
        font-size: 1.6vw;
        margin: 0 0 1.5vw;
      }
      @media only screen and (max-width: 450px) {
        font-size: 3.6vw;
        margin: 0 0 2vw;
      }
    }
    &__link {
      font-family: "Kanit";
      font-weight: 300;
      font-size: 1.05vw;
      color: #fff;
      display: block;
      margin: 0.5vw 0 0;
      @media only screen and (max-width: 1024px) {
        font-size: 1.45vw;
        margin: 1vw 0 0;
      }
      @media only screen and (max-width: 450px) {
        font-size: 3.45vw;
        margin: 1.5vw 0 0;
      }
    }
    &__qr {
      width: 53%;
      @media only screen and (max-width: 1024px) {
        width: 58%;
      }
    }
  }
`;
