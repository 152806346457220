import { useState, useEffect } from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import {
  Link as LinkScroll,
  animateScroll as scroll,
  scroller,
} from "react-scroll";

import { imgList } from "@constants/img";
const Header = () => {
  const location = useLocation();
  const [open, setOpen] = useState<boolean>(false);
  const [delayOpen, setDelayOpen] = useState<boolean>(false);
  const [delayClose, setDelayClose] = useState<boolean>(false);
  const [fadeHeader, setFadeHeader] = useState<boolean>(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () =>
        setFadeHeader(window.pageYOffset > 100)
      );
    }
  }, []);

  useEffect(() => {
    if (open) {
      setDelayClose(open);
      setTimeout(() => {
        setDelayOpen(open);
      }, 100);
    } else {
      setDelayOpen(open);
      setTimeout(() => {
        setDelayClose(open);
      }, 500);
    }
  }, [open]);

  useEffect(() => {
    if (location.hash === "") {
      setTimeout(() => {
        scroll.scrollTo(1);
      }, 100);
    }
    if (location.hash === "#home") {
      setTimeout(() => {
        scroll.scrollTo(1);
      }, 2000);
    }
    if (location.hash === "#review") {
      setTimeout(() => {
        scroller.scrollTo("review", {
          delay: 0,
          offset: -70,
        });
      }, 2000);
    }
    if (location.hash === "#about") {
      setTimeout(() => {
        scroller.scrollTo("about", {
          delay: 0,
          offset: -70,
        });
      }, 2000);
    }
    if (location.hash === "#contact") {
      setTimeout(() => {
        scroller.scrollTo("contact", {
          delay: 0,
          offset: -70,
        });
      }, 2000);
    }
  }, [location.hash]);

  return (
    <Content className="header">
      <div className={"header__inner" + (fadeHeader ? " active" : "")}>
        <div className="header__left">
          <a href="/" className="header__logo">
            <img src={imgList.logo} alt="" />
          </a>
          <ul className="header__menu">
            <li className="header__menu-li">
              {location.pathname === "/" ? (
                <LinkScroll
                  activeClass="active"
                  to="banner"
                  spy={true}
                  delay={0}
                >
                  หน้าแรก
                </LinkScroll>
              ) : (
                <a href="/#home">หน้าแรก</a>
              )}
            </li>
            <li className="header__menu-li">
              {location.pathname === "/" ? (
                <LinkScroll
                  activeClass="active"
                  to="review"
                  spy={true}
                  delay={0}
                  offset={-70}
                >
                  รีวิวจากลูกค้า
                </LinkScroll>
              ) : (
                <a href="/#review">รีวิวจากลูกค้า</a>
              )}
            </li>
            <li className="header__menu-li">
              {location.pathname === "/" ? (
                <LinkScroll
                  activeClass="active"
                  to="about"
                  spy={true}
                  delay={0}
                  offset={-70}
                >
                  เกี่ยวกับเรา
                </LinkScroll>
              ) : (
                <a href="/#about">เกี่ยวกับเรา</a>
              )}
            </li>
            <li className="header__menu-li">
              <a
                href="/services"
                className={location.pathname === "/services" ? "active" : ""}
              >
                บริการของเรา
              </a>
            </li>
            <li className="header__menu-li">
              <a
                href="/works"
                className={location.pathname === "/works" ? "active" : ""}
              >
                ผลงาน
              </a>
            </li>
            <li className="header__menu-li">
              {location.pathname === "/" ? (
                <LinkScroll
                  activeClass="active"
                  className="few"
                  to="contact"
                  spy={true}
                  delay={0}
                  offset={-70}
                >
                  ติดต่อเรา
                </LinkScroll>
              ) : (
                <a href="/#contact">ติดต่อเรา</a>
              )}
            </li>
          </ul>
        </div>
        <div className="header__right">
          <div className="header__icon">
            <a
              href="https://www.instagram.com/thechecker.inspector/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={imgList.icon_instagram} alt="" />
            </a>
            <a
              href="https://www.facebook.com/thechecker.team"
              target="_blank"
              rel="noreferrer"
            >
              <img src={imgList.icon_facebook} alt="" />
            </a>
            <a href="https://bit.ly/3hP4OHF" target="_blank" rel="noreferrer">
              <img src={imgList.icon_line} alt="" />
            </a>
            <a href="https://www.tiktok.com/@thechecker.inspector?lang=th-TH" target="_blank" rel="noreferrer">
              <img src={imgList.icon_tiktok} alt="" />
            </a>
          </div>
        </div>
        <img
          src={imgList.hamburger}
          alt=""
          className="header__hamburger"
          onClick={() => setOpen(!open)}
        />
      </div>
      <div className={"header__menu-mobile" + (delayClose ? " open" : "")}>
        <div
          className={"header__menu-mobile-inner" + (delayOpen ? " open" : "")}
        >
          <ul className="header__menu-mobile-ul">
            <li className="header__menu-mobile-li">
              {location.pathname === "/" ? (
                <LinkScroll
                  activeClass="active"
                  to="banner"
                  spy={true}
                  delay={0}
                  offset={-52}
                  onClick={() => setOpen(false)}
                >
                  หน้าแรก
                </LinkScroll>
              ) : (
                <a href="/#home">หน้าแรก</a>
              )}
            </li>
            <li className="header__menu-mobile-li">
              {location.pathname === "/" ? (
                <LinkScroll
                  activeClass="active"
                  to="review"
                  spy={true}
                  delay={0}
                  offset={-52}
                  onClick={() => setOpen(false)}
                >
                  รีวิวจากลูกค้า
                </LinkScroll>
              ) : (
                <a href="/#review">รีวิวจากลูกค้า</a>
              )}
            </li>
            <li className="header__menu-mobile-li">
              {location.pathname === "/" ? (
                <LinkScroll
                  activeClass="active"
                  to="about"
                  spy={true}
                  delay={0}
                  offset={-52}
                  onClick={() => setOpen(false)}
                >
                  เกี่ยวกับเรา
                </LinkScroll>
              ) : (
                <a href="/#about">เกี่ยวกับเรา</a>
              )}
            </li>
            <li className="header__menu-mobile-li">
              <a
                href="/services"
                className={location.pathname === "/services" ? "active" : ""}
                onClick={() => setOpen(false)}
              >
                บริการของเรา
              </a>
            </li>
            <li className="header__menu-mobile-li">
              <a
                href="/works"
                className={location.pathname === "/works" ? "active" : ""}
                onClick={() => setOpen(false)}
              >
                ผลงาน
              </a>
            </li>
            <li className="header__menu-mobile-li">
              {location.pathname === "/" ? (
                <LinkScroll
                  activeClass="active"
                  to="contact"
                  spy={true}
                  delay={0}
                  offset={-52}
                  onClick={() => setOpen(false)}
                >
                  ติดต่อเรา
                </LinkScroll>
              ) : (
                <a href="/#contact">ติดต่อเรา</a>
              )}
            </li>
            <div className="header__icon-mobile">
              <a
                href="https://www.instagram.com/thechecker.inspector/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={imgList.icon_instagram} alt="" />
              </a>
              <a
                href="https://www.facebook.com/thechecker.team"
                target="_blank"
                rel="noreferrer"
              >
                <img src={imgList.icon_facebook} alt="" />
              </a>
              <a href="https://bit.ly/3hP4OHF" target="_blank" rel="noreferrer">
                <img src={imgList.icon_line} alt="" />
              </a>
              <a
                href="https://www.tiktok.com/@thechecker.inspector?lang=th-TH"
                target="_blank"
                rel="noreferrer"
              >
                <img src={imgList.icon_tiktok} alt="" />
              </a>
            </div>
          </ul>
        </div>
      </div>
    </Content>
  );
};

export default Header;

const Content = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 4.444vw;
  z-index: 999;
  @media only screen and (max-width: 1024px) {
    height: 7vw;
  }
  @media only screen and (max-width: 450px) {
    height: 14vw;
  }
  .header {
    &__inner {
      position: relative;
      z-index: 2;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #fff;
      padding: 0 11%;
      transition: 0.3s all ease;
      &.active {
        background-color: rgba(255, 255, 255, 0.3);
      }
      @media only screen and (max-width: 1024px) {
        padding: 0 3%;
        &.active {
          background-color: rgba(255, 255, 255, 1);
        }
      }
      @media only screen and (max-width: 450px) {
        padding: 0 5%;
      }
    }
    &__left {
      width: 80%;
      height: 100%;
      display: flex;
      align-items: center;
    }
    &__right {
      width: 15%;
      height: 100%;
      @media only screen and (max-width: 1024px) {
        display: none;
      }
    }
    &__icon {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      > a {
        width: 19%;
        > img {
          width: 100%;
        }
      }
    }
    &__logo {
      width: 3.889vw;
      margin: 0 2.222vw 0 0;
      > img {
        width: 100%;
      }
      @media only screen and (max-width: 1024px) {
        width: auto;
        height: 90%;
        margin: 0;
        > img {
          width: auto;
          height: 100%;
        }
      }
    }
    &__menu {
      display: flex;
      align-items: center;
      @media only screen and (max-width: 1024px) {
        display: none;
      }
    }
    &__menu-li {
      font-family: "Kanit";
      font-weight: 400;
      font-size: 0.972vw;
      margin: 0 2.222vw 0 0;
      color: #18191f;
      transition: 0.3s all ease;
      & .active {
        color: #004998;
        font-weight: 700;
      }
    }
    &__hamburger {
      display: none;
      width: auto;
      height: 75%;
      cursor: pointer;
      @media only screen and (max-width: 1024px) {
        display: block;
      }
    }
    &__menu-mobile {
      position: absolute;
      top: 7vw;
      left: 0;
      width: 100%;
      height: calc(100vh - 7vw);
      display: none;
      @media only screen and (max-width: 1024px) {
        &.open {
          display: block;
        }
      }
      @media only screen and (max-width: 450px) {
        top: 14vw;
        height: calc(100vh - 14vw);
      }
    }
    &__menu-mobile-inner {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      -webkit-transform: translateY(-120%);
      transform: translateY(-120%);
      transition: -webkit-transform 0.5s cubic-bezier(0.8, 0, 0.1, 1);
      transition: transform 0.5s cubic-bezier(0.8, 0, 0.1, 1);
      transition: transform 0.5s cubic-bezier(0.8, 0, 0.1, 1),
        -webkit-transform 0.5s cubic-bezier(0.8, 0, 0.1, 1);
      will-change: transform;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      overflow-y: scroll;
      background: #fff;
      padding: 3%;
      border-top: solid 0.111vw #ebebeb;
      &.open {
        -webkit-transform: translateY(0);
        transform: translateY(0);
      }
      @media only screen and (max-width: 450px) {
        padding: 5%;
      }
    }
    &__menu-mobile-ul {
      width: 100%;
    }
    &__menu-mobile-li {
      font-family: "Kanit";
      font-weight: 400;
      font-size: 2.5vw;
      padding: 1% 0;
      & .active {
        color: #004998;
        font-weight: 700;
      }
      @media only screen and (max-width: 450px) {
        font-size: 4vw;
        padding: 2% 0;
      }
    }
    &__icon-mobile {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      margin: 1% 0 0;
      > a {
        width: 6vw;
        margin: 0 2vw 0 0;
        > img {
          width: 100%;
        }
      }
      @media only screen and (max-width: 450px) {
        margin: 2% 0 0;
        > a {
          width: 10vw;
          margin: 0 4vw 0 0;
        }
      }
    }
  }
`;
